import React, { useEffect, useState } from 'react';
import { PanelProps } from '@grafana/data';
import { SimpleOptions } from 'types';
import { css, cx } from '@emotion/css';
import { useStyles2, useTheme2 } from '@grafana/ui';
import { Map, View } from 'ol';
import TileLayer from 'ol/layer/Tile';
import XYZ from 'ol/source/XYZ.js';
import VectorLayer from 'ol/layer/Vector';
import VectorSource from 'ol/source/Vector';
import GeoJSON from 'ol/format/GeoJSON';
import Circle from 'ol/style/Circle';
import Fill from 'ol/style/Fill';
import Stroke from 'ol/style/Stroke';
import Style from 'ol/style/Style';
import Text from 'ol/style/Text';
import 'ol/ol.css';
import rsdm from '../utils/rsdm';
import { multidimensionalArrayToGeojson } from '../utils/geo';

interface Props extends PanelProps<SimpleOptions> {}

const getStyles = () => {
  return {
    wrapper: css`
      font-family: Open Sans;
      position: relative;
    `,
    svg: css`
      position: absolute;
      top: 0;
      left: 0;
    `,
    textBox: css`
      position: absolute;
      bottom: 0;
      left: 0;
      padding: 10px;
    `,
  };
};

function MapComponent(props) {
  const [state, setState] = useState({
    map: null,
    pointsSource: null,
    polygonsSource: null,
  });

  // Effect for map instantiation
  useEffect(() => {
    const map = new Map({
      target: 'map',
      layers: [
        new TileLayer({
          preload: Infinity,
          source: new XYZ({
            url: 'https://services.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}',
            attributions:
              'Tiles © <a href="https://services.arcgisonline.com/ArcGIS/rest/services/World_Topo_Map/MapServer">ArcGIS</a>',
          }),
        }),
      ],
      view: new View({
        center: [0, 0],
        zoom: 0,
        maxZoom: 17
      }),
    });
    console.log('map instantiated');

    // Create an empty vector source
    const pointsSource = new VectorSource({
      features: [], // Initially no features
    });

    // Create a vector layer with the empty source and style
    const pointsLayer = new VectorLayer({
      source: pointsSource,
      style: function (feature) {
        // Define a style for the points
        return new Style({
          image: new Circle({
            radius: 5,
            fill: new Fill({ color: 'red' }),
            stroke: new Stroke({ color: 'red', width: 1 }),
          }),
          text: new Text({
            font: '12px Calibri,sans-serif',
            overflow: true,
            fill: new Fill({
              color: '#000',
            }),
            stroke: new Stroke({
              color: '#fff',
              width: 3,
            }),
            text: feature.get('label'),
            offsetY: 20, // Offset the label 10 pixels down
          }),
        });
      },
      // Optional: Set a Z-index if you want to be explicit about the layer ordering
      zIndex: 2,
    });

    // Add the vector layer to the map
    map.addLayer(pointsLayer);

    const polygonsSource = new VectorSource({
      // Initially, the source is empty
    });

    const polygonsLayer = new VectorLayer({
      source: polygonsSource,
      style: new Style({
        fill: new Fill({
          color: 'rgba(176, 224, 230, 0.2)', // Translucent powder blue
        }),
        stroke: new Stroke({
          color: '#87CEEB', // Slightly darker powder blue for the border
          width: 1,
        }),
      }),
      // Optional: Set a Z-index if you want to be explicit about the layer ordering
      zIndex: 1,
    });

    // Add the layer to the map
    map.addLayer(polygonsLayer);

    // Set the map and sources in the state
    setState((prevState) => ({
      ...prevState,
      map,
      pointsSource,
      polygonsSource,
    }));
  }, []); // Empty dependency array means this runs once on mount

  useEffect(() => {
    if (props.points?.features?.length > 0) {
      // we should always at least have points
      console.log('props change:', props)
      // Read the features from the GeoJSON data
      const pointFeatures = new GeoJSON().readFeatures(props.points, {
        dataProjection: 'EPSG:4326', // assuming your data is in WGS84
        featureProjection: 'EPSG:3857', // assuming your map projection is Web Mercator
      });

      // replace the features to the source
      state.pointsSource.clear();
      state.pointsSource.addFeatures(pointFeatures);

      // zoom to points extent
      state.map.getView().fit(state.pointsSource.getExtent(), { padding: [50, 50, 50, 50] });

      // Read the polygons from the GeoJSON data
      state.polygonsSource.clear(); // remove old polys

      // iterate through the array to add each geojson feature collection individually
      props.polygons.forEach((featureCollection) => {
        const polygonFeature = new GeoJSON().readFeatures(featureCollection, {
          dataProjection: 'EPSG:4326', // assuming your data is in WGS84
          featureProjection: 'EPSG:3857', // assuming your map projection is Web Mercator
        });

        state.polygonsSource.addFeatures(polygonFeature);
      });
    }

    // If you need to clean up resources when the component unmounts or before the effect runs again,
    // you can return a cleanup function from here
    return () => {
      // Any cleanup logic goes here
    };
  }, [props, state]);

  return <div style={{ height: '100%', width: '100%' }} id="map" className="map-container" />;
}

export const SimplePanel: React.FC<Props> = ({ options, data, width, height }) => {
  const theme = useTheme2();
  const styles = useStyles2(getStyles);
  const [geojsonPoints, setGeojsonPoints] = useState({});
  const [geojsonPolygons, setGeojsonPolygons] = useState({});

  // This effect runs whenever 'data' prop changes
  useEffect(() => {
    // Code to execute when 'data' changes

    // transpose data to array of objs
    const transposedData = new Array(data.series[0].length).fill({}).map((_, index) => {
      const obj = {};
      data.series[0].fields.forEach((field) => {
        obj[field.name] = field.values[index];
      });
      return obj;
    });
    // iterate through to run the dispersion model and generate geojson
    const points = {
      type: 'FeatureCollection',
      features: [],
    };
    const polygons = [];

    transposedData.forEach((item) => {
      if (!item.lat || !item.long) {
        // can't plot anyway
        return;
      }

      points.features.push({
        type: 'Feature',
        properties: item,
        geometry: {
          coordinates: [item.long, item.lat],
          type: 'Point',
        },
      });

      if (item.avg_winDir && item.avg_windSpeed) {
        // without these, we really can't compute the plume
        const model = rsdm.run(
          // weather params
          item.avg_windSpeed / 2.237, // divide by 2.237 to convert from mph to m/s, speed of wind at sensor (m/s)
          item.avg_winDir, // direction of wind at sensor (degrees)
          'C', // A (very unstable) to F (very stable). Pasquill-Gifford stability category (https://www.youtube.com/watch?app=desktop&v=6UvN8yYHUqA)
          'rural', // urban or rural, used along with stability
          // emission source params
          0.0, // Stack x location (m)
          0.0, // Stack y location (m)
          1.5, // Stack height (m) -- scott says 3 to 5 feet above ground level
          0.01, // Stack diameter (m) -- like leaking from the threads of a connection, so tiny. 1cm diameter.
          1.0, // Plume velocity at stack tip (m/s) -- function of diameter and pressure, 1 m/s?
          item.avg_temperature || 20, // Plume temperature (C) -- ambient, use the weather sensor for this
          0.214 // Stack emission rate (g/s) -- 0.77kg/hour, whatever this is in grams/s. sensors read in ppm, scott will eventually get to tell us how to convert ppm to g/s
        );
        const geo = multidimensionalArrayToGeojson(model.rGrid, item.lat, item.long);
        polygons.push(geo);
      }
    });
    console.log('data',transposedData)
    setGeojsonPoints(points);
    setGeojsonPolygons(polygons);

    // If you need to clean up resources when the component unmounts or before the effect runs again,
    // you can return a cleanup function from here
    return () => {
      // Any cleanup logic goes here
    };
  }, [data]); // Only re-run the effect if 'data' changes

  return (
    <div
      className={cx(
        styles.wrapper,
        css`
          width: ${width}px;
          height: ${height}px;
        `
      )}
    >
      <MapComponent points={geojsonPoints} polygons={geojsonPolygons} />
      {/* <div className={styles.textBox}>
        {options.showSeriesCount && <div>Number of series: {data.series.length}</div>}
        <div>Text option value: {options.text}</div>
      </div> */}
    </div>
  );
};
