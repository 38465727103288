import { contours } from 'd3-contour';

function arrayMinMax(array) {
  // returns [min, max] computed from vals of the array; works with large arrays
  return array.reduce(
    ([min, max], val) => [Math.min(min, val), Math.max(max, val)],
    [Number.POSITIVE_INFINITY, Number.NEGATIVE_INFINITY]
  );
}

function addMetersToLatitude(lat, meters) {
  // pass a latitude and desired offset in meters; returns an offset latitude val
  const metersPerDegree = 111319.9;
  return lat + meters / metersPerDegree;
}

function addMetersToLongitude(lat, lon, meters) {
  // pass a latitude, longitude, and desired offset in meters; returns an offset longitude val
  const metersPerDegreeAtEquator = 111319.9;
  // Calculate the number of degrees of longitude per meter at the given latitude
  const degreesChange = meters / (metersPerDegreeAtEquator * Math.cos(lat * (Math.PI / 180)));
  return lon + degreesChange;
}

function convertCoords(coords, centerLat, centerLon, gridWidth, gridHeight) {
  // grid width/height are measures of the multidimensional array
  // converts coords from whatever cartesian made up space d3-contour outputs them in, to real world coordinates.
  // i have no idea what it's really doing. this, and pretty much everything lower in this file, was cargo-culted:
  // https://gist.github.com/mourner/14f52d4764299a8fcc204d699abdcc5b

  // the real-world geographic data is computed from the extent of the raster (or "grid", in our case).
  // RSDM makes a 5km x 5km grid centered on the pollutant output. since we know the lat/lon of the center
  // point, and we know the total grid is 50000 meters tall and wide:
  const minLng = addMetersToLongitude(centerLat, centerLon, -2500); // the minimum long is the center - 2500m
  const maxLng = addMetersToLongitude(centerLat, centerLon, 2500); // the max long is the center + 2500m
  const maxLat = addMetersToLatitude(centerLat, 2500); // the max lat is the center + 2500m
  const minLat = addMetersToLatitude(centerLat, -2500); // the minimum lat is the center - 2500m

  const result = [];
  for (let poly of coords) {
    const newPoly = [];
    for (let ring of poly) {
      if (ring.length < 4) {
        continue;
      }
      const newRing = [];
      for (let p of ring) {
        newRing.push([
          minLng + (maxLng - minLng) * (p[0] / gridWidth),
          maxLat - (maxLat - minLat) * (p[1] / gridHeight),
        ]);
      }
      newPoly.push(newRing);
    }
    result.push(newPoly);
  }
  return result;
}

function computeBreaks(grid, numberOfBreaks) {
  // creates N number of logarithmic breaks, working backwards from the data's max value,
  // and not exceeding the data's minimum value. any value less than 0 is not used for this computation.
  // each break will be 10x smaller than the previous one.
  const [min, max] = arrayMinMax(grid.flat().filter((value) => value > 0)); // min/max values of flattened array, ignoring anything < 0
  const breaks = [];

  for (let i = numberOfBreaks; i > 0; i--) {
    const divisor = Math.pow(10, i);
    const breakPoint = max / divisor;
    if (breakPoint > min) {
      breaks.push(breakPoint);
    }
  }

  return breaks;
}

export function multidimensionalArrayToGeojson(grid, centerLat, centerLon) {
  // pass the 501 item multidimensional array you get from RSDM, and the coords of the center of the array.
  // returns geojson contours of the dispersion.
  const breaks = computeBreaks(grid, 5); // 5 logarithmic breaks, but at some point we might want to set these to actual values for consistency across wells.
  const gridWidth = grid[0].length; // in our case, 501 items wide
  const gridHeight = grid.length; // and 501 items tall

  // make the d3 geojson polygons that seem to only exist in pixel space.
  // yes, they want our lovely multidimensional array to be flat in order to process. kinda dumb but ok. this must be why you have to specify size.
  const polygons = contours().size([gridWidth, gridHeight]).thresholds(breaks)(grid.flat());

  // convert "fake" geojson to real-world coordinates.
  const outputGeojson = {
    type: 'FeatureCollection',
    features: [],
  };

  for (let polygon of polygons) {
    if (polygon.coordinates.length === 0) {
      continue;
    }

    outputGeojson.features.push({
      type: 'Feature',
      properties: {
        value: polygon.value,
      },
      geometry: {
        type: polygon.type,
        coordinates: convertCoords(polygon.coordinates, centerLat, centerLon, gridWidth, gridHeight),
      },
    });
  }

  return outputGeojson;
}
